import React, { useEffect, useState, memo } from "react";
// import { MdOutlineBorderColor } from "react-icons/md";
import { IoMdColorFill } from "react-icons/io";
import "./modal.css"; // Import your CSS file
import { transformScale } from "@turf/turf";

const StyleModal = ({ setIsStyleModal, setStyle, style, title }) => {
    // console.error("Stylesheet check",style);
    const [borderColor, setBorderColor] = useState(style.color || "black");
    const [borderOpacity, setBorderOpacity] = useState(style.opacity || "1");
    const [fillColor, setFillColor] = useState(style.fillColor || "green");
    const [fillOpacity, setFillOpacity] = useState(style.fillOpacity || -1);
    const [zIndex, setZIndex] = useState(style.zIndex || 1000);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(!show);

    useEffect(() => {
        // Check if setIsStyleModal is defined before calling it
        if (typeof setIsStyleModal === "function") {
            setIsStyleModal(show);
        }
    }, [show, setIsStyleModal]);

    useEffect(() => {
        if (setStyle) {
            setStyle({
                color: borderColor,
                weight: borderOpacity,
                fillColor: fillColor,
                fillOpacity: fillOpacity,
                zIndex: zIndex,
            });
        }
        // console.log("Stylesheet", style);
    }, [borderColor, borderOpacity, fillColor, fillOpacity, zIndex, setStyle]);

    return (
        <>
            <a
                onClick={handleShow}
                href="#!"
                className="style-modal-trigger"
                style={{ transform: "translateY(-5px)" }}
            >
                <IoMdColorFill />
            </a>

            {show && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <button
                            className="modal-close-button"
                            onClick={handleClose}
                        >
                            &times;
                        </button>
                        <h3 className="modal-title"> Editor {title}</h3>

                        <div className="style-section border p-2">
                            <h5>Border Style</h5>
                            <div className="form-group ">
                                <label
                                    htmlFor="borderColorInput"
                                    className="font-weight-bold"
                                >
                                    Border Color
                                </label>
                                <input
                                    type="color"
                                    className="form-control"
                                    id="borderColorInput"
                                    value={borderColor}
                                    onChange={(e) =>
                                        setBorderColor(e.target.value)
                                    }
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="borderOpacityRange">
                                    Border Width
                                </label>
                                <input
                                    type="range"
                                    className="form-control"
                                    id="borderOpacityRange"
                                    min="0"
                                    max="5"
                                    step="0.01"
                                    value={borderOpacity}
                                    onChange={(e) =>
                                        setBorderOpacity(e.target.value)
                                    }
                                />
                                <small className="form-text text-muted">{`${borderOpacity}`}</small>
                            </div>
                        </div>
                        {fillOpacity > -1 && (
                            <div className="style-section border p-2">
                                <h4>Fill Style</h4>
                                <div className="form-group">
                                    <label
                                        htmlFor="fillColorInput"
                                        className="font-weight-bold"
                                    >
                                        Fill Color
                                    </label>
                                    <input
                                        type="color"
                                        className="form-control"
                                        id="fillColorInput"
                                        value={fillColor}
                                        onChange={(e) =>
                                            setFillColor(e.target.value)
                                        }
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="fillOpacityRange">
                                        Fill Opacity
                                    </label>
                                    <input
                                        type="range"
                                        className="form-control"
                                        id="fillOpacityRange"
                                        min="0"
                                        max="1"
                                        step="0.01"
                                        value={fillOpacity}
                                        onChange={(e) =>
                                            setFillOpacity(e.target.value)
                                        }
                                    />
                                    <small className="form-text text-muted">{`${fillOpacity}`}</small>
                                </div>

                                <div
                                    className="color-preview"
                                    style={{
                                        backgroundColor: fillColor,
                                        opacity: fillOpacity,
                                        borderColor: borderColor,
                                        borderWidth: "2px",
                                        borderStyle: "solid",
                                        height: "25px",
                                        width: "100%",
                                        marginTop: "10px",
                                    }}
                                ></div>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default memo(StyleModal);
